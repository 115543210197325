* {
    margin: 0;
    padding: 0;
}

.darkmode {
    background-color: #1e1e30 !important;
    color: white !important;
    border-color: #1e1e30 !important;
    transition: background-color 0.5s ease;
    transition: color 0.5s ease;

}


body {
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lato', sans-serif;
    color: black;
    transition: background-color 0.5s ease;
    transition: color 0.5s ease;
}

.dark {
    background-color: #161625;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lato', sans-serif;
    transition: background-color 0.5s ease;
}

.logout {
  background-color: #1a73e8;
  color: white;
  outline: none;
  padding: 0.7rem;
  border: 0px;
  font-weight: bold;
  border-radius: 0.2rem;
}

.container {
    padding: 3vh 0;
}

:root {
    --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

h3 {
    border-bottom: 1px solid #bbb;
    padding-bottom: 10px;
    margin: 40px 0 10px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
}

.left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:1em
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    top: auto;
    bottom: auto;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    border-radius: 34px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    border-radius: 50%;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #29293f;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  