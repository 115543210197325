.income-btn {
    cursor: pointer;
    background-color: #28a745;
    color: #fff;
    border: 0;
    display: block;
    font-size: 15px;
    float: left;
    margin: 10px 10px 10px 0px;
    padding: 10px;
    min-width: 40%;
}

.expense-btn {
    cursor: pointer;
    background-color: #fc4c4c;
    color: #fff;
    border: 0;
    float: right;
    /* display: inline; */
    font-size: 15px;
    margin: 10px;
    padding: 10px;
    min-width: 40%;
}

label {
    display: inline-block;
    margin: 10px 0;
}

input[type='text'],
input[type='number'] {
    border: 1px solid #dedede;
    border-radius: 2px;
    display: block;
    font-size: 16px;
    padding: 10px;
    width: 90%;
}